import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        contactJson {
          phone
          text
        }
    }
   `);
  return (
    <div className="call">
      <div className="call-box-top">
        {data.contactJson.phone && (
          <div className="call-phone">
            <strong>Telefon: </strong>
            {' '}
            { data.contactJson.text }
            {' '}
          </div>
        )}
      </div>
      {props.showButton && (
        <div className="call-box-bottom">
          <a href={`tel:${data.contactJson.phone}`} className="button">Pozovi</a>
        </div>
      )}
    </div>
  );
};

export default Call;
